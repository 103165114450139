/*---------------------------
  Public Pages 
----------------------------*/

.public-page {
  background: linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
    url("./assets/images/seguros-p5.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  height: auto;
}

/* Header */
.public-page header {
  background: #fff;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  padding: 0.65rem 0;
}

.public-page .header-wrap {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 460px) {
  .public-page .header-wrap {
    flex-direction: column;
  }
}

.public-page .navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.public-page .navbar li {
  position: relative;
}

.public-page .navbar>ul>li {
  position: relative;
  white-space: nowrap;
  padding: 8px 0 8px 20px;
}

.public-page .navbar a,
.public-page .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  border-bottom: 2px solid #fff;
  padding: 20px 5px;
  text-decoration: none;
  font-weight: 600;
}

.public-page .navbar a i,
.public-page .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.public-page .navbar a:hover,
.public-page .navbar .active,
.public-page.navbar .active:focus,
.public-page .navbar li:hover>a {
  border-color: #2ecc6f;
}

/* Main */
.public-page main {
  // padding-top: 5rem;
  padding-bottom: 3rem;
}

.public-page .content {
  color: white;
}

.public-page .hero {
  background: linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
    url("./assets/images/seguros-p5.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.public-page .boxes {
  background-color: #f3f3f3;
  padding: 3rem 0;
}

.public-page .box {
  position: relative;
  max-width: 800px;
  min-height: 20px;
  margin: 5px auto;
  height: 28.5rem;
  display: flex;
  flex-flow: row wrap;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.box img {
  vertical-align: middle;
}

.box div {
  bottom: 0;
  // background: rgb(0, 0, 0); /* Fallback color */
  // background: rgba(33, 32, 32, 0.5); /* Black background with opacity */
  // color: #ffffff;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  min-height: 20px;
}

.box h2 {
  text-align: center;
  color: #2ecc6f;
  font-size: 3rem;
}

.box h5 {
  color: #333;
  margin-bottom: 7px;
  font-size: 1.1rem;
  text-align: center;
}

.box p {
  color: #161616;
  text-align: justify;
}

/* Homepage */
.pretitle {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.6;
}

.title {
  color: #f8ac00;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 0.2em;
}

/* Sign-up */
.eligibility-list li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.eligibility-list svg {
  margin-right: 10px;
}

/*---------------------------
  Standardized Conditions Titles
----------------------------*/
.h-list {
  margin-bottom: 0.9rem;
}

/*---------------------------
 Standardized Conditions css in Menu Title Starts here
----------------------------*/

.ol-list {
  list-style-type: lower-roman;
  font-size: 1rem;
  margin-bottom: 0.8rem;
  margin-left: 1rem;
  line-height: 1.2rem
}
.bx--header__name {
  width: 16rem;
  background: #fff;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 15px;
  // color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #2ecc6f;
  text-transform: uppercase;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /* Important for vertical align on mobile phones */
  font-weight: 600;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 14.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  // color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
// .dropdown-content a:hover {
//   background-color: #ddd;
// }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/*---------------------------
 Standardized Conditions css in Menu Title Ends here
----------------------------*/

/*---------------------------
  Admin Pages 
----------------------------*/
.admin-page {
  background: #eff1f2;
}

.bx--header {
  background: #2ecc6f;
  border-bottom: 1px solid #fff;
}

.bx--header__name {
  width: 16rem;
  background: #fff;
}

.bx--header__name img {
  max-width: 160px;
  max-height: 45px;
  margin: auto;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 1rem;
}

.user-details svg {
  margin-bottom: 5px;
}

.bx--side-nav__submenu-title {
  color: #2ecc6f;
}

.dashboard {
  margin-top: 2rem;
}

.dashboard .card-title {
  font-size: 1.2rem;
}

/*---------------------------
  Common styles
----------------------------*/
.content p {
  margin-bottom: 1.6em;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.bx--form-item,
.bx--dropdown__wrapper,
.bx--list-box__wrapper {
  margin-top: 0.8rem;
}

.field-error {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.33333;
  letter-spacing: 0.32px;
  overflow: hidden;
  margin: 0.25rem 0 0;
  color: #da1e28;
}

.password-field-message {
  font-size: 0.70rem;
  font-weight: 400;
  line-height: 1.33333;
  letter-spacing: 0.32px;
  overflow: hidden;
  margin: 0.25rem 0 0;
  color: gray;
}

.btn-fluid {
  width: 100%;
  max-width: 100%;
}

.Toastify__toast--success {
  background: #2ecc6f !important;
}

.Toastify__toast--warning {
  background: #f8ac00 !important;
}

.bx--btn-set button {
  margin-right: 5px;
}

.required label.bx--label::before,
.required legend.bx--label::before {
  content: "*";
  color: #ef4800;
  font-size: 1.1rem;
}

.bx--label.section-header {
  font-size: 15px;
  font-weight: 900;
}

.section-subtitle {
  margin-bottom: 1.5rem;
}

.last-fieldset {
  margin-bottom: 0;
}

.bx--fieldset p {
  font-size: 14px;
  margin-bottom: 1rem;
}

.bx--fieldset ol {
  margin-left: 1rem;
  margin-bottom: 2rem;
  list-style: decimal;
}

.bx--fieldset ol li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.bx--label {
  font-weight: 600;
}

.bx--text-input:disabled {
  background-color: #d1ecf1;
  color: #0c5460 !important;
  cursor: pointer;
  -webkit-text-fill-color: #0c5460;
}

.bx--label--disabled,
.bx--form__helper-text--disabled {
  color: #161616;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

/* Tab wizard */
.tab-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab-navigation .bx--btn--ghost svg {
  margin: 0 5px;
}

.tab-navigation .bx--btn--ghost:hover,
.tab-navigation .bx--btn--ghost:active {
  color: #ef4800;
}

.tab-navigation .bx--btn--ghost:hover {
  background-color: #fff;
}

.bdesc {
  margin-top: 20px;
}

.sbox {
  margin-top: 80px;
}

.bx--slider-text-input,
.bx-slider-text-input {
  ///////////////////////////////////////////////////
  display: inline-block;
  width: 7rem;
  height: 2.5rem;
  -moz-appearance: textfield;
  text-align: center;
}

//Testing cellphone boz

.tel-box {
  display: flex;
  margin: 1rem;
  margin-top: 0;
}

.select-box {
  margin: 1rem;
  width: 60px;
  height: 40px;
}

.flag-img {
  width: 60px;
  height: 40px;
  position: absolute;
  border: 2px solid black;
  border-radius: 5px;
}

.select-box select {
  font-family: "Lato", sans-serif;
  width: 60px;
  height: 40px;
  background: none;
  position: absolute;
  border: none;
  font-size: 1rem;
  padding-left: 4rem;
  outline: none;
  -webkit-appearance: none;
}

.tel {
  font-family: "Lato", sans-serif;
  width: 100%;
  height: 40px;
  background: none;
  border: 2px solid black;
  outline: none;
  margin: 1rem;
  font-size: 1rem;
  padding-left: 1.5rem;
  color: black;
  font-weight: thick;
  border-radius: 5px;
}

.output {
  margin: 1rem;
  margin-top: 0;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-self: center;
}

.result {
  font-family: "Source Code Pro", monospace;
  width: auto;
  padding: 0.25rem;
  border-radius: 5px;
  height: 30px;
  background-color: pink;
  color: red;
  font-size: 1.5rem;
  margin-left: 1rem;
}

.bx--slider {
  position: relative;
  width: 100%;
  min-width: 8.5rem;
  max-width: 40rem;
  padding: 1rem 0;
  margin: 0 1rem;
  cursor: pointer;
}

.footer {
  color: #eff1f2;
  padding: 1rem 0;
}

//Responsive mobile settings
@media screen and (min-width: 0px) and (max-width: 400px) {
  .bx--row {
    display: block;
  }

  .parent {
    height: auto;
  }
}